.wrap-container {
  background: url('./img/bg-big.png');
  height: auto;
  min-height: 100vh;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.wrap-container-mo {
  background: url('./img/bg-mo.png');
  height: auto;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.sc-address {
  text-align: center !important;
}

.container {
  padding-bottom: 50px;
}

.logo img {
  width: 850px;
  height: 200px;
}

.title {
  color: #fff;
  text-transform: uppercase;
  font-size: 35px;
  font-weight: 700;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content-page {
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
  background: #fffc;
  height: 500px;
  border-radius: 40px;
  padding: 25px;
}

.scroll-content {
  height: 100%;
  overflow-y: scroll;
  direction: rtl;
}

.scroll-content::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #fff;
  margin-top: 40px;
  margin-bottom: 40px;
}

.scroll-content::-webkit-scrollbar {
  width: 24px;
}

.scroll-content::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 1px 1px 16px rgba(0, 0, 0, .3);
}

.title-content {
  text-transform: uppercase;
  font-size: 45px;
  font-weight: 700;
  text-decoration: underline;
  direction: ltr;
  padding-top: 15px;
}

.text-content {
  padding: 25px 25px 35px 50px;
  color: black;
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  direction: ltr;

}

.connect-wallet .wallet-adapter-button-trigger {
  background-color: #732997;
  border-radius: 99px;
  border: 2px solid #fff;
  justify-content: center;
}

.connect-wallet .wallet-adapter-button {
  height: 60px;
  gap: 10px;
  width: 350px;
}

.group-address {
  text-align: center;
  padding-bottom: 15px;
  padding-top: 15px;
}

.group-address.end {
  padding-bottom: 10px !important;
}

.custom-class .ant-message-notice-content {
  display: flex;
  width: fit-content;
  padding: 5px 15px 5px 7px;
  pointer-events: all;
  margin-left: auto;
  justify-content: right;
  box-shadow: 0px 6px 20px rgba(120, 122, 155, 0.18);
  border-radius: 36.5px;
  background: #ffffff;
}

.custom-class .ant-message-warning .anticon.anticon-exclamation-circle {
  background: linear-gradient(223.03deg,
      rgba(250, 198, 104, 0.2) -1.23%,
      rgba(221, 161, 52, 0.2) 102.46%);
  border-radius: 50%;
  padding: 10px;
}

.custom-class .ant-message-success .anticon.anticon-check-circle {
  background: linear-gradient(223.03deg,
      rgba(29, 230, 107, 0.2) -1.23%,
      rgba(12, 183, 79, 0.2) 102.46%);
  border-radius: 50%;
  padding: 10px;
}

.custom-class .ant-message-error .anticon.anticon-close-circle {
  background: linear-gradient(223.03deg,
      rgba(250, 94, 94, 0.2) -1.23%,
      rgba(242, 69, 69, 0.2) 102.46%);
  border-radius: 50%;
  padding: 10px;
}

.custom-class span:nth-child(2) {
  color: #1e2329 !important;
}

.btn-claim {
  background-color: #732997;
  border-radius: 99px;
  border: 2px solid #fff;
  height: 45px;
  width: 240px;
  color: #fff;
  font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.custom-class .ant-message-custom-content {
  display: flex;
  align-items: center;
}

.action-claim {
  padding-bottom: 20px;
}

.hash:hover {
  cursor: pointer;
  text-decoration: underline;
}

.line-address {
  background: #fff;
  border-radius: 30px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  margin: 0 auto;
  margin-top: 5px;
}

.btn-claim:disabled {
  background: #999999cf;
  color: #0000004f;
}

@media screen and (max-width: 768px) {
  .line-address {
    width: 100%;
  }

  .logo {
    padding: 20px;
  }

  .logo img {
    width: 100%;
    height: auto;
  }

  .title {
    font-size: 25px;
    padding-top: 0px !important;
  }

  .content-page {
    width: 90%;
    padding: 15px;
    height: 430px;
  }

  .title-content {
    font-size: 30px;
    padding: 15px 10px;
  }

  .text-content {
    font-size: 18px;
    padding: 5px 20px 25px 25px;
  }

  .connect-wallet .wallet-adapter-button {
    height: 50px;
    font-size: 12px;
    width: 270px;
  }

  .btn-claim {
    font-size: 18px;
  }
}